@import '~bootstrap/scss/bootstrap.scss';

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #f9f9f9;
}

.font-14 {
    font-size: 14px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-0-important {
    margin: 0 !important;
}

.flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.is-bold {
    font-weight: bold;
}

.background-openfaas-important {
    /*background-color: #3b5ee3;*/
}

.bg-openfaas {
    background-color: #3b5ee3;
}

.border-radius-bottom-3 {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.border-radius-bottom-5 {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.height-100 {
    height: 100%;
}

.padding-12-0 {
    padding: 12px 0;
}

.font-size-0-important {
    font-size: 0 !important;
}

.background-color-inherit-important {
    background-color: inherit !important;
}

.radius-right-5 {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.min-height-49 {
    min-height: 49px;
}

.height-350 {
    height: 350px;
}

a {
    color: #337ab7;
}

td a.btn-xs {
    line-height: .5;
    font-size: 0.6rem;
}

.btn-group-xs > .btn, .btn-xs {
    padding  : .25rem .4rem;
    font-size  : 14px;
    line-height  : .5;
    border-radius : .2rem;
}

.table tr td {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    vertical-align: middle;
}

.border-success {
    border-color: #d6e9c6 !important;
}

.border-success > .card-header {
    border-bottom-color: #d6e9c6 !important;
}

.card-header.bg-success {
    background-color: #dff0d8 !important;
}


.color-success {
    color: #3c763d !important;
}

.color-failure {
    color: red;
}

.color-white {
    color: #fff;
}

.color-white:hover {
    color: #fff;
}

/* NavBar */
.navbar-inverse {
    background-color: #3b5ee3;
    border-color: #3b5ee3;
}

.navbar-inverse .navbar-text,
.navbar-inverse .navbar-text a {
    color: #fff;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
    color: #9cd3ff;
    background-color: transparent;
}

.navbar-inverse .navbar-nav > li > a {
    color: #fff;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
    color: #9cd3ff;
    background-color: #234be0;
}

.navbar-brand img {
    height: 30px;
}

.valid-tooltip {
    top: initial;
    bottom: 5px;
    left: 5px;
}

.overflow-hidden {
    overflow: hidden;
}

@include media-breakpoint-up(lg) {
    .height-lg-360 {
        height: 360px;
    } 
}